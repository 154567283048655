var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"align"},[_c('div',{staticClass:"contentBlock"},[_vm._m(0),_c('div',{staticClass:"slid_3"},[_vm._m(1),_c('div',[_c('div',[_vm._m(2),_c('div',[_c('svg',{class:'mts',attrs:{"xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","viewBox":"0 0 850.4 850.4","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('defs',[_c('path',{attrs:{"id":"a","d":"M0 0h850.4v850.4H0z"}})]),_c('clipPath',{attrs:{"id":"b"}},[_c('use',{attrs:{"xlink:href":"#a","overflow":"visible"}})]),_c('g',{attrs:{"clip-path":"url(#b)"}},[_c('path',{attrs:{"fill":"#FF0032","d":"M850.4 0H0v850.4h850.4V0z"}}),_c('path',{attrs:{"fill":"#FFF","d":"M258.7 65.4l-62.5 129.8-62.5-129.8H65.5v196.2h47.8V121.2l61.1 118.6H218l61.1-118.6v140.4H327V65.4h-68.3zM566.6 65.4v39.1H652l-.2 157.1h47.8l.2-157.1h85.5V65.4H566.6zM737.4 719.5c-1.2 7.8-4.3 14.3-9.4 19.1-4.8 4.6-11.2 7.8-19.2 9.7-7.9 1.8-19.4 2.9-31.7 2.9s-23.9-1.6-33.4-4.9c-9.2-3.1-16.5-8.6-21.6-16.3-5.2-7.7-7.8-18.9-7.8-33.1v-20.1c0-14.3 2.6-25.4 7.8-33.1 5.1-7.7 12.4-13.1 21.6-16.3 9.5-3.2 20.7-4.9 33.4-4.9s23.8 1.1 31.7 2.9c8 1.8 14.4 5.1 19.2 9.7 5 4.9 8.2 11.3 9.4 19.1h47.9c-1.3-16.2-6.8-30-16.4-41-8.8-10.1-20.9-17.5-36-22-14.7-4.4-33.9-6.7-55.7-6.7-22.9 0-42.8 3.3-59.2 9.8-16.6 6.6-29.4 17.6-38.3 32.7-8.5 14.5-12.9 33.8-13.2 57.5v2.2l2.6.1-2.6.1v2.2c.3 23.7 4.7 43 13.2 57.5 8.8 15.1 21.7 26.1 38.3 32.7 16.3 6.5 36.2 9.8 59.2 9.8 22.9 0 41.1-2.3 55.7-6.7 15.1-4.5 27.2-11.9 36-22 9.6-11 15.1-24.8 16.4-41l-47.9.1z"}})])]),_c('img',{attrs:{"src":"img/image_18.png"}})]),_c('p',[_vm._v("Для внесения платежа Вам потребуется паспорт, ФИО заемщика, номер договора займа. БИК 045004641; Идентификатор ФИНМОЛЛ 060421043.")])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h3',[_vm._v("Платежи по договору займа можно внести любым из перечисленных способов:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("ВАЖНО!")]),_vm._v(" Номер договора займа "),_c('br',{staticClass:"mobileOnly"}),_vm._v(" должен содержать 8 знаков – 00123456 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('span',{staticClass:"num"},[_vm._v("1")]),_vm._v("В сети салонов связи и пунктах обслуживания сервиса «Золотая корона»:")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',[_c('span',{staticClass:"num"},[_vm._v("2")]),_vm._v("Оплатить через сеть терминалов «Элекснет» ")]),_c('div',[_c('img',{attrs:{"src":"img/image_20.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',[_c('span',{staticClass:"num"},[_vm._v("3")]),_vm._v(". В магазинах kari и kari KIDS:")]),_c('div',[_c('img',{attrs:{"src":"img/image_23.png"}}),_c('img',{attrs:{"src":"img/image_24.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',[_c('span',{staticClass:"num"},[_vm._v("5")]),_vm._v("Оплатить банковской картой на сайте ООО МКК «ФИНМОЛЛ»")]),_c('p',[_c('a',{attrs:{"href":"#"}},[_vm._v("https://finmoll.com")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',[_c('span',{staticClass:"num"},[_vm._v("5")]),_vm._v("Оплатить в Личном кабинете клиента на сайте ООО МКК «ФИНМОЛЛ»")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b',[_c('span',{staticClass:"num"},[_vm._v("5")]),_vm._v("Оплатить в мобильном приложении клиента «ФИНМОЛЛ КАБИНЕТ КЛИЕНТА»")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"requisites"},[_c('b',[_c('span',{staticClass:"num"},[_vm._v("6")]),_vm._v("По банковским реквизитам ООО МКК «ФИНМОЛЛ»")]),_c('div',{staticStyle:{"padding-top":"0"}},[_c('img',{attrs:{"src":"img/image_25.png"}})]),_c('p',[_c('span',[_vm._v("Комиссия:")]),_vm._v("устанавливается банком, в соответствии с действующими тарифами")]),_c('p',[_c('span',[_vm._v("Зачисление:")]),_vm._v("от 3 до 5 банковских дней")]),_c('div',[_c('p',[_c('span',[_vm._v("Необходимо:")]),_c('br'),_vm._v(" ○ Номер договора займа и ФИО заемщика")]),_c('p',[_vm._v(" ○ Реквизиты ООО МКК «ФИНМОЛЛ»")])])]),_c('p',{staticClass:"misc"},[_vm._v("Для перевода через Сбербанк России Вам необходимо указать реквизиты ООО МКК «ФИНМОЛЛ»")]),_c('div',{staticClass:"requisitesText"},[_c('div',[_c('p',[_c('b',[_vm._v("Получатель платежа:")]),_vm._v(" ООО МКК «ФИНМОЛЛ»")]),_c('p',[_c('b',[_vm._v("Банк получателя:")]),_vm._v(" ПАО Сбербанк")]),_c('p',[_c('b',[_vm._v("БИК:")]),_vm._v(" 044525225")]),_c('p',[_c('b',[_vm._v("ИНН:")]),_vm._v(" 7705954143")]),_c('p',[_c('b',[_vm._v("КПП:")]),_vm._v(" 785250001")]),_c('p',[_c('b',[_vm._v("ОГРН/ОГРНИП:")]),_vm._v(" 1117746509638")]),_c('p',[_c('b',[_vm._v("Кор. счет:")]),_vm._v(" 30101810400000000225")]),_c('p',[_c('b',[_vm._v("Расчетный счет:")]),_vm._v(" 40701810738000002048")]),_c('p',[_c('b',[_vm._v("Назначение платежа:")]),_vm._v(" Погашение займа по договору № ХХХХХХХХ (8 знаков), Иванов Иван")])])])])
}]

export { render, staticRenderFns }