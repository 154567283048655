<template>
  <div class="align">
    <div class="contentBlock">
      <div class="title">
        <h3>Платежи по договору займа можно внести любым из перечисленных способов:</h3>
      </div>
      <div class="slid_3">
        <p>
          <span>ВАЖНО!</span> Номер договора займа <br class="mobileOnly"> должен содержать 8 знаков – 00123456
        </p>
        <div>

          <div>
            <b><span class="num">1</span>В сети салонов связи и пунктах обслуживания сервиса «Золотая корона»:</b>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 850.4 850.4" v-bind:svg-inline="''" v-bind:class="'mts'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><path id="a" d="M0 0h850.4v850.4H0z"/></defs><clipPath id="b"><use xlink:href="#a" overflow="visible"/></clipPath><g clip-path="url(#b)"><path fill="#FF0032" d="M850.4 0H0v850.4h850.4V0z"/><path fill="#FFF" d="M258.7 65.4l-62.5 129.8-62.5-129.8H65.5v196.2h47.8V121.2l61.1 118.6H218l61.1-118.6v140.4H327V65.4h-68.3zM566.6 65.4v39.1H652l-.2 157.1h47.8l.2-157.1h85.5V65.4H566.6zM737.4 719.5c-1.2 7.8-4.3 14.3-9.4 19.1-4.8 4.6-11.2 7.8-19.2 9.7-7.9 1.8-19.4 2.9-31.7 2.9s-23.9-1.6-33.4-4.9c-9.2-3.1-16.5-8.6-21.6-16.3-5.2-7.7-7.8-18.9-7.8-33.1v-20.1c0-14.3 2.6-25.4 7.8-33.1 5.1-7.7 12.4-13.1 21.6-16.3 9.5-3.2 20.7-4.9 33.4-4.9s23.8 1.1 31.7 2.9c8 1.8 14.4 5.1 19.2 9.7 5 4.9 8.2 11.3 9.4 19.1h47.9c-1.3-16.2-6.8-30-16.4-41-8.8-10.1-20.9-17.5-36-22-14.7-4.4-33.9-6.7-55.7-6.7-22.9 0-42.8 3.3-59.2 9.8-16.6 6.6-29.4 17.6-38.3 32.7-8.5 14.5-12.9 33.8-13.2 57.5v2.2l2.6.1-2.6.1v2.2c.3 23.7 4.7 43 13.2 57.5 8.8 15.1 21.7 26.1 38.3 32.7 16.3 6.5 36.2 9.8 59.2 9.8 22.9 0 41.1-2.3 55.7-6.7 15.1-4.5 27.2-11.9 36-22 9.6-11 15.1-24.8 16.4-41l-47.9.1z"/></g></svg>
              <img src="img/image_18.png"/>
            </div>
            <p>Для внесения платежа Вам потребуется паспорт, ФИО заемщика, номер договора займа. БИК
              045004641; Идентификатор ФИНМОЛЛ 060421043.</p>
          </div>

          <div>
            <b><span class="num">2</span>Оплатить через сеть терминалов «Элекснет» </b>
            <div>
              <img src="img/image_20.png" />


            </div>
          </div>

          <div>
            <b><span class="num">3</span>. В магазинах kari и kari KIDS:</b>
            <div>
              <img src="img/image_23.png" />
              <img src="img/image_24.png" />
            </div>
          </div>

          <!--            <div>-->
          <!--              <b><span class="num">4</span>Оплатить онлайн на сайте Золотая корона</b>-->
          <!--              <p><a href="#">https://koronapay.com/</a> через вкладку «Погашение кредита».</p>-->
          <!--            </div>-->

          <div>
            <b><span class="num">5</span>Оплатить банковской картой на сайте ООО МКК «ФИНМОЛЛ»</b>
            <p><a href="#">https://finmoll.com</a></p>
          </div>

          <div>
            <b><span class="num">5</span>Оплатить в Личном кабинете клиента на сайте ООО МКК «ФИНМОЛЛ»</b>
          </div>

          <div>
            <b><span class="num">5</span>Оплатить в мобильном приложении клиента «ФИНМОЛЛ КАБИНЕТ КЛИЕНТА»</b>
          </div>


          <div>
            <div class="requisites">
              <b><span class="num">6</span>По банковским реквизитам ООО МКК «ФИНМОЛЛ»</b>
              <div style="padding-top: 0">
                <img src="img/image_25.png" />
              </div>
              <p><span>Комиссия:</span>устанавливается банком, в соответствии с действующими тарифами</p>
              <p><span>Зачисление:</span>от 3 до 5 банковских дней</p>
              <div>
                <p><span>Необходимо:</span><br> ○ Номер договора займа и ФИО заемщика</p>
                <p> ○ Реквизиты ООО МКК «ФИНМОЛЛ»</p>
              </div>
            </div>
            <p class="misc">Для перевода через Сбербанк России Вам необходимо указать реквизиты ООО МКК «ФИНМОЛЛ»</p>
            <div class="requisitesText">
              <div>
                <p><b>Получатель платежа:</b> ООО МКК «ФИНМОЛЛ»</p>
                <p><b>Банк получателя:</b> ПАО Сбербанк</p>
                <p><b>БИК:</b> 044525225</p>
                <p><b>ИНН:</b> 7705954143</p>
                <p><b>КПП:</b> 785250001</p>
                <p><b>ОГРН/ОГРНИП:</b> 1117746509638</p>
                <p><b>Кор. счет:</b> 30101810400000000225</p>
                <p><b>Расчетный счет:</b> 40701810738000002048</p>
                <p><b>Назначение платежа:</b> Погашение займа по договору № ХХХХХХХХ (8 знаков), Иванов Иван</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "how_to_repay"
}
</script>

<style scoped>
span { font-family: 'Lato'; font-weight: bold }
.slid_3 { line-height: 150% }
.slid_3 > p > span { color: #0d5740; }
.slid_3 > p { padding: 3.47vw 0 }
.num { width: 4.27vw; height: 4.27vw; line-height: 4.2vw; padding: 0.73vw; background: #45b537; border-radius: 6.93vw; color: #fff; display: inline-block; text-align: center; }
.slid_3 > div > div > b { line-height: 8vw }
.slid_3 > div > div > p { }
.slid_3 > div > div > p > a { color: rgba(19,21,21,.7019607843137254) }
.slid_3 > div > div > div > p {  padding-top: 5.60vw  }
.slid_3 > div > div > div > img { margin: 4.27vw}
.slid_3 > div > div > p > span { color: #131515; padding-right: 2vw }
.slid_3 > div > div > .requisites > p > span { color: #131515; padding-right: 2vw; font-size: 3.53vw; font-family: 'Lato'; font-weight: bold }
.slid_3 > div > div > .requisites > p { }
.slid_3 > div > div > .requisites > div { padding-top: 5.60vw }
.slid_3 > div > div > .requisites > div > img { margin: 4.27vw }
.slid_3 > div > div > .requisites > b { line-height: 13.87vw }
.slid_3 > div > div > .requisites > div > p { }
.slid_3 > div > div > .requisites > div > p > span { color: #131515; padding-right: 2vw; font-size: 3.53vw; font-family: 'Lato'; font-weight: bold }
.requisitesText { display: flex }
.requisitesText>div { display: flex; flex-direction: column; padding:0.80vw 2vw 0 0 }
.requisitesText>div>p { color: #131515 }
.requisitesText>div>p>b { font-size: 4.5vw }
.misc { padding: 3.47vw 0}
.mts { width: 15vw; height: 15vw }
.qiwi { width: 38.82vw }
@media (min-width: 760px) {
  p { font-size: 0.97vw }
  span { font-size: 0.97vw }
  b { font-size: 1.13vw }
  .slid_3 { line-height: 150% }
  .slid_3 > p { line-height: 150%; padding: 0.48vw }
  .slid_3 > p > span {  }
  .slid_3 > div > div > b { line-height: 150% }
  .slid_3 > div > div > p { padding: 0.48vw }
  .slid_3 > div > div > p > a { font-size: 1.45vw; }
  .slid_3 > div > div > div > p {  padding-top: 0.73vw }
  .slid_3 > div > div > div > img { margin: 0.56vw}
  .slid_3 > div > div > p > span { padding-right: 2vw }
  .slid_3 > div > div > .requisites > p { }
  .slid_3 > div > div > .requisites > p > span { padding-right: 1vw; font-size: 0.97vw }
  .slid_3 > div > div > .requisites > div { padding-top: 0.73vw }
  .slid_3 > div > div > .requisites > div > img { margin: 0.56vw }
  .slid_3 > div > div > .requisites > b { line-height: 150% }
  .slid_3 > div > div > .requisites > div > p { padding-right: 2vw }
  .slid_3 > div > div > .requisites > div > p > span { padding-right: 1vw; font-size: 0.97vw }
  .num { width: 0.89vw; height: 0.89vw; line-height: 150%; padding: 0.32vw; border-radius: 11px; display: inline-block; margin-right: 0.89vw; text-align: center }
  .requisitesText > div { display: flex; flex-direction: column; padding:0.80vw 2vw 0 0 }
  .requisitesText > div > span { }
  .requisitesText > div > p { }
  .requisitesText>div>p>b { font-size: 1.13vw }
  #slid_1:checked + div, #slid_2:checked + div, #slid_3:checked + div, #slid_4:checked + div { background: #E7F3E5; }
  .mts { width: 5vw; height: 5vw }
  .qiwi { width: 10.65vw; height: 4vw }
}
@media (min-width: 1240px) {
  a { font-size: 14px }
  p { font-size: 12px }
  span { font-size: 12px }
  b { font-size: 14px }
  .slid_3 { }
  .slid_3 > h3 { }
  .slid_3 > p { padding: 9px }
  .slid_3 > p > span { }
  .slid_3 > div > div > b { }
  .slid_3 > div > div > p { padding: 0 }
  .slid_3 > div > div > p > a { font-size: 12px }
  .slid_3 > div > div > div > p {  padding-top: 0.73vw }
  .slid_3 > div > div > div > img { margin: 10px}
  .slid_3 > div > div > p > span { padding-right: 40px }
  .slid_3 > div > div > .requisites > p { }
  .slid_3 > div > div > .requisites > p > span { padding-right: 10px; font-size: 12px }
  .slid_3 > div > div > .requisites > div { padding-top: 10px }
  .slid_3 > div > div > .requisites > div > img { margin: 10px }
  .slid_3 > div > div > .requisites > b { }
  .slid_3 > div > div > .requisites > div > p { padding-right: 40px }
  .slid_3 > div > div > .requisites > div > p > span { padding-right: 10px; font-size: 12px }
  .num { width: 24px; height: 24px; padding: unset; border-radius: 19px; margin-right: 17px  }
  .requisitesText > div { padding:15px 40px 0 0 }
  .requisitesText > div > span { }
  .requisitesText>div>p>b { font-size: 12px }
  #slid_1:checked + div, #slid_2:checked + div, #slid_3:checked + div, #slid_4:checked + div { }
  .mts { width: 50px; height: 50px }
  .qiwi { width: 132px; height: 50px }
}
</style>